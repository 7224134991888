<template>
    <div id="carousel">
        <vueper-slides slide-multiple duration="4000" v-if="visibleSlides" :visible-slides="visibleSlides"
            :arrows="spotlight.carousel_show_arrows" infinite :arrows-outside="false" class="no-shadow"
            :slide-ratio="1 / 4" :dragging-distance="70">
            <vueper-slide v-for="i in testimonials.length" :key="i">
                <template #content>
                    <!-- :autoplay="false" -->
                    <TestimonialCard :spotlight="spotlight" :key="testimonials[i - 1].id" autoplay="false"
                        :testimonial="testimonials[i - 1]" />
                </template>
            </vueper-slide>
            <template #arrow-left>
                <div class="left mr-0">
                    <v-btn size="x-small" color="primary" rounded="xl" icon>
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                </div>
            </template>

            <template #arrow-right>
                <div class="left mr-0">
                    <v-btn size="x-small" color="primary" rounded="xl" icon>
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                </div>
            </template>

            <template #bullet="{ active, slideIndex, index }">
                <v-icon :color="active ? 'primary' : 'grey'"
                    style="font-size: 15px; cursor: pointer;">mdi-circle</v-icon>
            </template>
        </vueper-slides>
        <div class="ad" @click="$r.openInNewTab('https://skooad.com')" v-if="spotlight?.show_skooad_brand"> 
            <div class="text">
                Made with Skooad!
            </div>
        </div>
    </div>
</template>

<script>
import TestimonialCard from './TestimonialCard.vue';
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
    props: {
        testimonials: {
            type: Array,
            default: [],
        },
        spotlight: {
            type: Object,
            default: null,
        },
    },
    components: {
        TestimonialCard,
        VueperSlides,
        VueperSlide
    },
    data() {
        return {
            index: 0,
            page: 0,
            visibleSlides: 0
        };
    },
    created() {
        this.$nextTick(() => {
            this.updateVisibleSlides();
            window.addEventListener('resize', this.updateVisibleSlides);
        })
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateVisibleSlides);
    },
    methods: {
        updateVisibleSlides() {
            this.visibleSlides = Math.floor(document.getElementById('carousel').offsetWidth / 350);
        }
    }
};
</script>

<style>
.carousel-enter-active,
.carousel-leave-active {
    transition: transform 0.5s ease;
}

.carousel-enter,
.carousel-leave-to {
    transform: translateX(100%);
}

.vueperslide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.vueperslides__parallax-wrapper {
    height: 470px;
}

::-webkit-scrollbar {
    width: 15px;
    height: 15px;
}

::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 7px;
    background-color: rgba(0, 0, 0, 0.15);
    box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
}

::-webkit-scrollbar-corner {
    background-color: transparent;
}
</style>

<style scoped lang="scss">
// :deep(.answers) {
//     max-height: 200px;
//     overflow-y: auto;
//     overflow-x: hidden;
// }

:deep(.testicard) {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.03) !important;
    border: 1px solid #e0e0e0;
    min-width: 300px;
    max-width: 450px;
    width: unset;
    // width: -webkit-fill-available;
    margin: 0 10px;
}

.ad {
    display: flex;
    justify-content: center;
    align-items: center;

    .text {
        cursor: pointer;
        font-size: 12px;
        color: #5c5c5c;
        margin: 0 10px;

        &:hover {
            transform: scale(1.1);
            transition: transform 0.5s;
        }
    }
}

:deep(.vueperslides__bullet) {
    margin: 0 5px 10px 5px;
}
</style>