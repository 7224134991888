<template>
    <div class="form w100" v-if="!loading"
        :style="`font-family: ${datas.font || 'Arial'}, sans-serif!important; background-image: url(${`${this.$r.config.domain}/uploads/images/${datas.wallpaper}`})`">
        <div class="header">
            <div class="logo-container-container">
                <div class="logo-container">
                    <!-- <img class="logo" :src="datas.logo" alt="Logo de Skooad" /> -->
                    <ImageStock class="logo" :src="datas.logo" alt="Logo de Skooad" />
                </div>
            </div>
            <div class="button-container-container">
                <div class="button-container">
                    <v-btn elevation="0" rounded="xl" size="x-small" :color="datas.color || 'primary'"
                        @click="$r.openInNewTab('https://skooad.com/')">
                        Get cool testimonials with SKOOAD >
                    </v-btn>
                </div>
            </div>
        </div>
        <div class="container">
            <div v-if="datas.active" class="h100">

                <h3 class="mb-2" v-if="steps.find(step => step.active)?.title">
                    {{ steps.find(step => step.active).title }}
                </h3>

                <component @loadTestimonial="loadTestimonial" :edit="edit" :datas="datas"
                    :is="steps.find(step => step.active)?.component" @changeType="changeType" @nextStep="nextStep"
                    :goStep="goStep" :testimonial="testimonial" />
            </div>
            <div v-else class="info h100 w100 d-flex aic jcc fdc">
                This form is no longer active, please contact the owner for more information.
            </div>

        </div>
        <div class="progress-bar-container" v-if="datas.progress_bar && datas.active">
            <div class="progress-bar" :style="`width: ${getProgress()}%;background-color: ${datas.color || 'primary'}`">
            </div>
        </div>
    </div>
</template>

<script>
const stepComponents = require.context('./steps', false, /\.vue$/);
const components = stepComponents.keys().reduce((components, fileName) => {
    const componentName = fileName.split('/').pop().replace(/\.\w+$/, '');
    components[componentName] = stepComponents(fileName).default;
    return components;
}, {});
import { supabase } from '@/supabase'
import ImageStock from '@/components/utils/ImageStock.vue';

export default {
    name: 'TestimonialForm',

    data() {
        return {
            steps: [
                {
                    active: true,
                    component: '1Welcome',
                    title: 'Welcome'
                },
                {
                    active: false,
                    component: '2Record',
                    title: null
                },
                // {
                //     active: false,
                //     component: '3Review',
                //     title: 'Your testimonial'
                // },
                {
                    active: false,
                    component: '4Contact',
                    title: 'Almost done!'
                },
                {
                    active: false,
                    component: '5Thanks',
                    title: 'Thank you!'
                }
            ],
            type: 'video',
            loading: true,
            datas: null,
            testimonial: null
        }
    },

    props: {
        editable: {
            type: Boolean,
            default: false
        },
        goStep: {
            type: Object,
            default: null
        },
        magnet: {
            type: Object,
            default: null
        },
        edit: {
            type: Boolean,
            default: false
        }
    },

    async created() {

        this.loading = true

        let stepURL = this.$route.query.step;
        if (stepURL) {
            this.steps.forEach((step, index) => {
                step.active = index + 1 == stepURL;
            });
        }
        if (this.goStep) {
            this.steps.forEach((step, index) => {
                step.active = step.component == this.goStep.component;
            });
        }

        if (this.$route.query.testimonials_id) await this.loadTestimonial()
        else if (!this.edit && !this.$route.query.reloaded) await this.postTestimonial()

        if (this.testimonial?.type == 'text') this.steps.find(s => s.component == '2Record').component = '2TextInput'

        await this.getMagnet()
        if (!this.edit && this.datas.active) await supabase.from('magnets').update({ viewed: this.datas.viewed + 1 }).eq('id', this.$route.params.id)

        this.loading = false
    },

    methods: {
        async changeType(type) { 
            this.type = type
            if (type == 'text') {
                await supabase
                    .from('testimonials')
                    .update({ type: type })
                    .eq('id', this.testimonial.id)

                if (this.steps.find(s => s.component == '2Record')) this.steps.find(s => s.component == '2Record').component = '2TextInput'
            } else {
                await supabase
                    .from('testimonials')
                    .update({ type: type })
                    .eq('id', this.testimonial.id)

                if (this.steps.find(s => s.component == '2TextInput')) this.steps.find(s => s.component == '2TextInput').component = '2Record'
            }
            this.nextStep()
        },
        async loadTestimonial() {
            let response = await supabase
                .from('testimonials')
                .select('*,contacts(*)')
                .eq('id', this.$route.query.testimonials_id)
            this.testimonial = response.data[0]
            return response.data[0]
        },
        async postTestimonial() {
            let response = await supabase
                .from('testimonials')
                .insert([{ magnet: this.$route.params.id }]).select('*')
            this.$router.push({ query: { testimonials_id: response.data[0].id } })
            this.testimonial = response.data[0]

            return

        },
        async getMagnet() {
            let response
            if (this.magnet) {
                response = this.magnet
                this.datas = response
            } else {
                response = await supabase
                    .from('magnets')
                    .select('*, questions(*)')
                    .eq('id', this.$route.params.id)
                this.datas = response.data[0]
            }

            this.datas.logo = this.datas.logo || `${this.$r.config.domain}/logo.png`

            let welcome = this.steps.find(step => step.title == 'Welcome')
            welcome.title = this.datas.welcome_title || 'Welcome'
            if (this.datas.welcome_page == false) {
                welcome.active = false
                this.steps[1].active = true
            }
            return

        },
        nextStep(howmany = 1) {
            this.loading = true;
            let activeIndex = this.steps.findIndex(step => step.active);
            this.steps[activeIndex].active = false;
            this.steps[activeIndex + howmany].active = true;
            //add step to url and keep query params
            this.$router.push({ query: { ...this.$route.query, step: activeIndex + howmany + 1 } });
            this.loading = false;
        },
        getProgress() {
            let step = this.steps.find(step => step.active)
            if (step.component != '2Record') {
                step = this.steps.findIndex(step => step.active)
            } else {
                let firstdata = this.datas.questions.findIndex(prompt => !prompt.filmed)
                firstdata = firstdata == -1 ? this.datas.questions.length : firstdata
                step = this.steps.findIndex(step => step.active) + firstdata
            }
            return (100 / (this.steps.length + this.datas.questions.length + 1)) * (step + 1)
        }

    },
    components: {
        ImageStock,
        ...components
    }
}
</script>

<style scoped lang="scss">
.mobile {
    .container {
        padding: 0 0.5rem !important;
        box-shadow: none !important;
        border: none !important;

        .button-container {
            left: -260px !important;
        }
    }


}

.mobile {
    .container {
        min-width: calc(100vw - 20px) !important;
        min-height: unset !important;
        height: fit-content !important;
        padding: 3rem 1rem !important;
        max-width: calc(100vw - 40px) !important;
    }

    .button-container {
        left: -90px !important;
    }
}

.form {
    height: 100%;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-size: cover;
    background-color: #fcfcfc;


    .container {
        min-width: 620px;
        max-width: 650px;
        min-height: 400px;
        margin: 0 auto;
        padding: 3rem;
        background-color: white;
        border-radius: 10px;
        box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.1);
        border: 1px solid #f1f1f1;
        max-height: 75vh;
        overflow-y: auto;


    }

    .header {
        display: flex;
        justify-content: space-between
    }

    .button-container-container {
        width: 0;
        height: 0;
    }

    .button-container {
        position: relative;
        top: -9px;
        left: -0px;
        width: 270px;
        display: flex;
        justify-content: center;
        align-items: center;

        button {
            width: 100%;
        }
    }

    .logo-container-container {
        width: 0;
        height: 0;
    }

    .logo-container {
        background-color: white;
        border-radius: 50%;
        position: relative;
        top: -42px;
        left: -280px;
        width: 85px;
        height: 85px;
        overflow: hidden;
        border: 1px solid #f1f1f1;
        z-index: 10;

        display: flex;
        justify-content: center;
        align-items: center;

        .logo {
            display: flex;
            justify-content: center;

            img {
                margin: 0;
                width: 100%;
                height: initial !important;
            }
        }
    }

    .progress-bar-container {
        margin-top: 20px;
        width: 300px;
        height: 10px;
        background-color: #607d8b9c;
        display: flex;
        border-radius: 5px;
        overflow: hidden;

        .progress-bar {
            background-color: #de6348;
        }
    }
}
</style>