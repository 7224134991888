<template>
    <div class="w100" :style="input.style">
        <div v-if="input.editable != false" class="d-flex aic w100" :class="{ disabled: proDisabled() }">
            <div v-if="labelled && !edition" class="label-edit d-flex" @click="clickEd()">
                <div :style="styleLabel + value == null ? 'opacity:0.7;' : ''">
                    {{ value || input.name }}
                </div>
                <v-icon color="primary">mdi-pencil</v-icon>
            </div>
            <div class="w100" v-else>
                <div class="pro-disabled" v-if="input.pro && !$r.user?.pro">

                </div>

                <!-- STARS -->
                <div :color="color" v-if="input.type === 'stars'">
                    <h4>
                        {{ label }}
                    </h4>
                    <div class="d-flex aic mb-6">
                        <div v-for="i in 5" :key="i">
                            <v-icon v-if="i <= val" @click="val = i" :color="color">mdi-star</v-icon>
                            <v-icon v-else @click="val = i">mdi-star-outline</v-icon>
                        </div>
                    </div>
                </div>

                <!-- TEXT -->
                <div class="text" v-if="input.type === 'text'">
                    <v-text-field style="transform: translateY(-3px);" :hide-details="input.hideDetails || null"
                        :readonly="proDisabled()" v-model="val" variant="outlined" :label="label" outlined dense />
                </div>

                <!-- PHONE -->
                <div class="text" v-if="input.type === 'phone'">
                    <v-text-field :rules="rules.phone" style="transform: translateY(-3px);"
                        :hide-details="input.hideDetails || null" :readonly="proDisabled()" v-model="val"
                        variant="outlined" :label="label" outlined dense />
                </div>

                <!-- EMAIL -->
                <div class="text" v-if="input.type === 'email'">
                    <v-text-field :rules="rules.email" style="transform: translateY(-3px);"
                        :hide-details="input.hideDetails || null" :readonly="proDisabled()" v-model="val"
                        variant="outlined" :label="label" outlined dense />
                </div>

                <!-- TEXTAREA -->
                <div class="textarea" v-else-if="input.type === 'textarea'">
                    <v-textarea :hide-details="input.hideDetails || null" :readonly="proDisabled()" v-model="val"
                        variant="outlined" :label="label" outlined dense />
                </div>

                <!-- VIDEO -->
                <div class="video mb-12" v-else-if="input.type === 'video'">
                    <h4>
                        {{ label }}
                    </h4>
                    <ImageStock class="mb-4" width="300px" :src="val" v-if="val" />
                    <v-file-input style="display: none" ref="file" hide-details v-model="val" accept="video/*"
                        outlined />
                    <div class="d-flex aic jcc w100">
                        <v-btn elevation="0" rounded="xl" @click="click()" color="primary" outlined>
                            {{ val ? 'Change' : 'Upload' }}
                        </v-btn>
                        <v-btn v-if="val" class="ml-4" elevation="0" rounded="xl" @click="val = null" color="error"
                            outlined>
                            Delete
                        </v-btn>
                    </div>
                </div>

                <!-- IMAGE -->
                <div class="image d-flex aic jcc fdc wfc" v-else-if="input.type === 'image'">
                    <div class="d-flex aic">
                        <div class="d-flex aic" @click="click()" v-if="loaded">
                            <ImageStock width="75px" height="75px" :src="val?.name || val" rounded v-if="val" />
                            <v-btn v-else size="x-large" icon elevation="0"
                                style="border-radius: 50%!important;overflow: hidden;" color="grey" outlined>
                                <v-icon color="white">
                                    mdi-image
                                </v-icon>
                            </v-btn>
                            <v-file-input style="display: none" ref="file" hide-details v-model="val" accept="image/*"
                                outlined />
                        </div>
                        <div class="ml-4 info">
                            {{ label }} <v-icon class=" close-btn" v-if="val" @click="val = null"
                                color="error">mdi-close</v-icon>
                        </div>
                    </div>
                </div>

                <!-- TOGGLE -->
                <div class="toggle" v-else-if="input.type === 'toggle'"> 
                    <h4>
                        {{ label }}
                    </h4>
                    <v-switch :readonly="proDisabled()" color="primary" inset density="compact" v-model="val" />
                </div>

                <!-- SLIDER -->
                <div class="slider" v-else-if="input.type === 'slider'">
                    <h4>
                        {{ label }}
                    </h4>
                    <div class="d-flex aic">
                        <v-slider :readonly="proDisabled()" class="mr-4" color="primary" show-ticks="always" hide-details
                            v-model="val" :max="input.max" :min="input.min || '5'" :step="input.step || 5" />
                        <h5 v-if="val" class="mr-4">
                            <span>{{ val }} {{ input.unit }}</span>
                        </h5>
                    </div>
                </div>

                <!-- COLOR -->
                <div class="color mb-6" v-else-if="input.type === 'color'">
                    <v-menu offset-y :close-on-content-click="false">
                        <template v-slot:activator="{ props }">
                            <div class="d-flex aic ">
                                <div v-bind="props" class="d-flex aic w100">
                                    <div v-if="val" class="mr-4"
                                        style="width: 30px; height: 30px; border-radius: 50%; margin-left: 10px;"
                                        :style="{ backgroundColor: val }" />
                                    <v-text-field ref="color-input" variant="outlined" hide-details :color="val"
                                        v-model="val" outlined dense :label="label" />
                                    <v-icon @click="val = '#FFFFFF00'" v-if="val" color="error"
                                        class="ml-4">mdi-close</v-icon>
                                </div>
                            </div>
                        </template>
                        <v-color-picker hide-canvas hide-inputs show-swatches v-model="val" />
                    </v-menu>
                </div>

                <!-- DROPDOWN -->
                <div class="dropdown mt-4" v-else-if="input.type === 'dropdown'">
                    <v-select color="primary" clearable :multiple="input.multiple" v-model="val" item-title="text"
                        :items="input.options" outlined dense :label="label" variant="outlined" />
                </div>

                <!-- JSON ARRAY -->
                <div class="json-array" v-else-if="input.type === 'json-array'">
                    <h4 class="mb-2">
                        {{ label }}
                    </h4>
                    <div v-for="(item, index) in input.keys">
                        <v-text-field :readonly="proDisabled()" hide-details class="mb-4" density="comfortable"
                            @keyup="updateValueAfter" v-model="val[item]" variant="outlined" :label="item" outlined
                            dense />
                    </div>
                </div>

                <!-- LIST STRING ARRAY -->
                <div class="list-string-array" v-else-if="input.type === 'list-string-array'">
                    <h4 class="mb-2">
                        {{ label }}
                    </h4>
                    <v-btn elevation="0" v-if="val.length === 0" @click="val.push('')" color="primary" text>
                        <v-icon>mdi-plus</v-icon>
                        Add
                    </v-btn>
                    <div v-for="(item, index) in val" class="d-flex aic mb-4">
                        <v-text-field :readonly="proDisabled()" hide-details density="comfortable"
                            @keyup="updateValueAfter" v-model="val[index]" variant="outlined" outlined dense />
                        <v-icon color="primary" class="ml-4" @click="val.splice(index, 1), updateValueAfter()">
                            mdi-trash-can-outline
                        </v-icon>
                        <v-icon color="primary" class="ml-4" @click="val.push('')">mdi-plus-circle-outline</v-icon>
                        <v-icon color="primary" class="ml-4" @click="$r.openInNewTab(val[index])">
                            mdi-open-in-new
                        </v-icon>
                    </div>
                </div>

                <!-- MULTIPLES TOGGLES -->
                <div class="toggles" v-else-if="input.type === 'multiples-toggle'">
                    <h4>
                        {{ label }}
                    </h4>
                    <div class="d-flex fdc jcfs">
                        <div v-for="(item, index) in input.keys" :key="index" class="d-flex aic mr-4 mt-2"
                            @click="updateValueAfter()">
                            <v-icon v-if="val[item]" @click="val[item] = !val[item]"
                                :color="val[item] ? 'primary' : 'grey'"
                                style="cursor: pointer; font-size: 30px;transform: translateY(-4px);">
                                mdi-checkbox-marked
                            </v-icon>
                            <v-icon :key="index" v-else @click="val[item] = !val[item]"
                                :color="val[item] ? 'primary' : 'grey'"
                                style="cursor: pointer; font-size: 30px;transform: translateY(-4px);">
                                mdi-checkbox-blank-outline
                            </v-icon>
                            <span style="cursor: pointer; font-size: 14px; color: grey;" class="ml-2"
                                @click="val[item] = !val[item]">
                                {{ item }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- If askrequired, chekbox to ask for required -->
            <div v-if="input.askRequired" class="d-flex aic mr-4">
                <v-checkbox color="primary" hide-details dense v-model="valRequired" class="mr-6" />
                <span style="cursor: pointer; font-size: 14px; color: grey;"
                    @click="valRequired = !valRequired">Required</span>
            </div>

            <div v-if="input.pro">
                <v-tooltip location="bottom">
                    <template v-slot:activator="{ props }">
                        <v-btn @click="$r.openInNewTab('https://skooad.com/#pricing')" icon v-bind="props" color="#E8DEF8"
                            elevation="0" size="small" class="mr-2">
                            <b>Pro</b>
                        </v-btn>
                    </template>
                    <span>Discover our pro plan</span>
                </v-tooltip>
            </div>

            <v-icon v-if="labelled && edition" @click="updateValue(), this.edition = false" color="primary" class="ml-4"
                style="font-size: 30px;transform: translateY(-4px);">mdi-check</v-icon>

        </div>
        <div v-else>
            <div class="d-flex aic">
                <div v-if="input.type === 'image'">
                    <ImageStock width="75px" height="75px" :src="val?.name || val" rounded v-if="val" />
                </div>
                <div v-else>
                    {{ val }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { supabase } from '@/supabase';
import ImageStock from '@/components/utils/ImageStock.vue'

export default {
    name: 'Input',
    props: {
        input: {
            type: Object,
            default: null
        },
        object: {
            type: Object,
            default: null
        },
        value: {
            type: String,
            default: null
        },
        table: {
            type: String,
            default: 'magnets'
        },
        color: {
            type: String,
            default: 'primary'
        },
        noTimeout: {
            type: Boolean,
            default: false
        },
        labelled: {
            type: Boolean,
            default: false
        },
        styleLabel: {
            type: String,
            default: null
        }
    },
    components: {
        ImageStock
    },
    watch: {
        async val() {
            if (this.object[this.input.model] !== this.val) {
                if (['text', 'textarea', 'slider'].includes(this.input.type) && this.noTimeout == false) {
                    await this.updateValueAfter()
                } else if (['image', 'video'].includes(this.input.type)) {
                    await this.updateImage()
                } else {
                    await this.updateValue()
                }
            }
            if (this.object[this.input.model] === undefined) this.object[this.input.model] = this.val
        },
        async valRequired() {
            if (this.object[this.input.askRequired] !== this.valRequired) {
                await this.updateRequired()
                this.$emit('save')
            }
        }
    },
    data() {
        return {
            timeout: null,
            lastFile: null,
            val: null,
            valRequired: null,
            loaded: true,
            edition: !this.labelled,
            rules: {
                phone: [
                    v => !!v || 'Phone number is required',
                    v => /^\d{10}$/.test(v) || 'Phone number must be 10 digits'
                ],
                email: [
                    v => !!v || 'Email is required',
                    v => /.+@.+\..+/.test(v) || 'Email must be valid'
                ]
            }
        };
    },
    computed: {
        label() {
            return this.input.name + (this.input.required ? '*' : '')
        }
    },
    created() {
        this.lastFile = this.object?.[this.input.model]
        this.val = this.value

        if (this.input?.askRequired) {
            this.valRequired = this.object?.[this.input.askRequired]
        }

        if (this.input.type === 'json-array') {
            this.val = this.object[this.input.model] || {}
        }

        // if this.labelled and keypress enter, update value and close edition
        if (this.labelled) {
            document.addEventListener('keypress', (e) => {
                if (e.key === 'Enter') {
                    this.edition = false
                    this.updateValue()
                }
            })
        }
    },
    methods: {
        proDisabled() {
            return this.input.pro && !this.$r.user.informations?.pro
        },
        clickEd() {
            this.edition = true
            //focus on input
            this.$nextTick(() => {
                document.getElementsByTagName('input')[0].focus()
            })
        },
        click() {
            this.$refs.file.click();
        },
        deleteImage() {
            this.val = null
            this.updateImage()
        },

        async updateImage(e) {
            let fileName = await this.$r.uploadFile(this.val, this.lastFile, this.input.directory || null) || null
            if (this.input.update == false) {
                this.object[this.input.model] = fileName
                this.val = fileName
                return
            }
            const response = await supabase
                .from(this.input.table || this.table)
                .update({ [this.input.model]: fileName })
                .eq('id', this.object.id || this.$route.params.id);

            this.val = fileName
            this.object[this.input.model] = fileName

            this.loaded = false
            setTimeout(() => {
                this.$emit('save')
                this.loaded = true
            }, 1000);
        },

        async updateValueAfter() {
            if (this.timeout) clearTimeout(this.timeout);

            this.timeout = setTimeout(async () => {
                await this.updateValue();
            }, 1000);
        },

        async updateValue() {
            if (this.input.type === 'email' || this.input.type === 'phone') {
                let rules = this.rules[this.input.type]
                for (let rule of rules) {
                    let res = rule(this.val)
                    if (res !== true) {
                        return
                    }
                }
            }

            if (this.input.update == false) {
                this.object[this.input.model] = this.val
                this.input.callback?.()
                return
            }
            let body = {};
            body[this.input.model] = this.val;
            const response = await supabase
                .from(this.input.table || this.table)
                .update(body)
                .eq('id', this.object.id || this.$route.params.id).select('*')
            this.val = response.data[0][this.input.model]
            this.object[this.input.model] = this.val

            this.$emit('save')
            return
        },

        async updateRequired() {
            let body = {};
            body[this.input.askRequired] = this.valRequired;
            await supabase
                .from(this.table)
                .update(body)
                .eq('id', this.object.id || this.$route.params.id).select('*')
            this.object[this.input.askRequired] = this.valRequired
            return
        }
    }
};
</script>

<style scoped lang="scss">
.disabled {
    opacity: 0.7;
}

.image {
    cursor: pointer;
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
    transition: all 0.3s;

    &:hover {
        transition: all 0.3s;
        //transform: scale(1.01);
        opacity: 0.9;

        .close-btn {
            opacity: 1;
        }
    }

    .close-btn {
        opacity: 0;
    }

}

.label-edit {
    cursor: pointer;
    transition: all 0.3s;
    padding: 10px;
    border-radius: 10px;

    &:hover {
        transition: all 0.3s;
        background-color: #f5f5f5;

        i {
            opacity: 1;
        }
    }

    i {
        margin-left: 10px;
        opacity: 0;
    }

}
</style>