<template>
    <div id="my-widget" class="my-widget" :class="spotlight.floating_position">
        <div class="type-floating" v-if="spotlight && spotlight.type == 'floating'">
            <div>
                <div class="testimonial testimonial-video" :style="{ right: getRightWithSize(spotlight.bubbles_sizes) }"
                    v-if="testiHover" @mouseleave="testiHover = null">
                    <TestimonialCard :autoplay="true" :testimonial="testiHover" :fromSpotlight="true"
                        :spotlight="spotlight" />
                </div>
                <div v-if="testimonials.length" class="bubbles" :style="{ 'background-color': 'rgba(0, 0, 0, 0.0)' }">
                    <div v-for="testimonial in testimonials" class="testi">
                        <Bubble :testimonial="testimonial" :testiHover="testiHover" :addView="addView"
                            @testiHover="changeTesti" :spotlight="spotlight" />
                    </div>
                </div>
                <div v-else-if="error" class="error">
                    <p>{{ error }}</p>
                    <p>
                        Visit the <a target="_blank" href="http://app.skooad.com/">Skooad dashboard</a> to configure
                        your
                        spotlight.
                    </p>
                </div>
            </div>
            <div class="ad-logo-container" @click="$r.openInNewTab('https://skooad.com')"
                v-if="spotlight?.show_skooad_brand">
                <img class="ad-logo" :src="require(`@/assets/logo.png`)" alt="Logo de Skooad" />
            </div>
        </div>
    </div>
</template>

<script>
import TestimonialCard from '../components/TestimonialCard.vue';
import Bubble from './Bubble.vue';

export default {
    props: {
        editing: {
            type: Boolean,
            default: false,
        },
        domain: {
            type: String,
            default: null,
        },
        testimonials: {
            type: Array,
            default: [],
        },
        allTestimonials: {
            type: Array,
            default: [],
        },
        spotlight: {
            type: Object,
            default: null,
        },
        error: {
            type: String,
            default: null,
        },
        addView: {
            type: Function,
            default: () => { },
        },
    },
    components: {
        TestimonialCard,
        Bubble,
    },
    data() {
        return {
            testiHover: null,
        };
    },
    methods: {
        getRightWithSize(size) {
            if (size == 'Small') {
                return '75px';
            } else if (size == 'Medium') {
                return '95px';
            } else {
                return '115px';
            }
        },
        playVideo(testimonial) {
            const videoUrl = `${testimonial.video}/`;
            const videoPlayer = document.createElement('video');
            videoPlayer.src = videoUrl;
            videoPlayer.controls = true;
            videoPlayer.autoplay = true;
            videoPlayer.style.position = 'fixed';
            videoPlayer.style.left = '50%';
            videoPlayer.style.top = '50%';
            videoPlayer.style.transform = 'translate(-50%, -50%)';
            videoPlayer.style.zIndex = '10000';
            videoPlayer.style.width = '80%';
            videoPlayer.style.height = '80%';
            document.body.appendChild(videoPlayer);

            videoPlayer.addEventListener('click', () => {
                document.body.removeChild(videoPlayer);
            });
        },
        changeTesti(testimonial) {
            this.testiHover = null
            //get index of testiHover in testimonials
            const index = this.testimonials.findIndex((testi) => testi.id == testimonial.id);
            this.$nextTick(() => {
                this.testiHover = testimonial;
                //set the top of the testimonial to the mouse position
                this.$nextTick(() => {
                    console.log(event.clientY);

                    const testimonial = document.querySelector('.testimonial-video');
                    let height = this.spotlight.bubbles_sizes == 'Small' ? 50 : this.spotlight.bubbles_sizes == 'Medium' ? 75 : 100;
                    if (this.spotlight.floating_position != 'top') {
                        testimonial.style.top = `${index * height}px`;
                    } else {
                        testimonial.style.left = `${index * height}px`;
                    }
                    //transform: translateY(-50%);
                    // testimonial.style.transform = `translateY(${event.clientY}px)`;
                });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
:deep(div),
:deep(p),
:deep(a),
:deep(span),
:deep(h1),
:deep(h2),
:deep(h3),
:deep(h4),
:deep(h5),
:deep(h6) {
    line-height: 22px !important;
}
</style>


<style lang="scss">
@import '../assets/scss/global.scss';
@import '../assets/scss/_variables.scss';
@import url('https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css');

$taille-bulle : 50px;

.error {
    background-color: rgba(0, 0, 0, 0.8);
    padding: 5px 10px;
    border-radius: 10px;

    p {
        color: white;
    }

}

.ad-logo-container {
    position: absolute;
    right: 22px;
    width: 50px;
    height: 50px;
    background-color: #ffeeee;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        transform: scale(1.1);
        transition: all 0.5s;
        cursor: pointer;
    }

    .ad-logo {
        width: 80%;
    }
}

.my-widget {
    position: fixed;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9999;

    &.left {
        right: auto;
        left: 10px;

        .testimonial-video {
            right: auto;
            left: 90px;
        }
    }

    &.top {
        top: 50px;
        left: 50%;

        .bubbles {
            display: flex;
            align-items: flex-end;
        }

        .testi {
            margin: 0 5px;
        }

        .testimonial-video {
            top: 90px;
            width: fit-content;
        }
    }

    .bubbles {
        z-index: 9999;
        background-color: rgba(0, 0, 0, 0.8);
        padding: 5px 10px;
        border-radius: 10px;

        .img {
            width: $taille-bulle;
            height: $taille-bulle;
            border-radius: 100%;
            margin: 3px 0;

        }
    }

    .testimonial-video {
        position: absolute;
        right: 90px;
        border-radius: 10px;
        overflow: visible !important;
        // top: calc(50% - 150px);
        height: fit-content;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

        video {
            height: 100%;
        }
    }

    .video-container {
        width: $taille-bulle;
        height: $taille-bulle;
        overflow: hidden;
        border-radius: 100%;
        margin: 10px 0;

        // video {
        //     width: auto;
        //     height: $taille-bulle;
        // }

    }

    .bubble.selected {
        transform: scale(1.1);
        transition: transform 0.5s;
    }
}
</style>
