<template>
    <div class="dashboard">
        <!-- Left Menu -->
        <Menu :view="view" v-if="!$r.mobile" @updateView="$emit('updateView', $event)" :linksMenu="linksMenu"
            :linksMenuBottom="linksMenuBottom" />

        <div class="view" v-if="currentMenuItem">
            <div class="d-flex aic jcsb">
                <h1 class="title">
                    {{ currentMenuItem.name }}
                </h1>
                <v-btn color="primary" v-if="currentMenuItem.action" @click="$refs.view?.actionHeader()" elevation="0"
                    rounded="xl">
                    {{ currentMenuItem.action }}
                </v-btn>
            </div>
            <h2 class="subtitle st mb-6^=$">
                {{ currentMenuItem.subtitle }}
            </h2>
            <component ref="view" :view="view" :is="view" @updateView="$emit('updateView', $event)" />
        </div>
    </div>
</template>

<script>
const stepComponents = require.context('./DashboardViews', false, /\.vue$/);
const components = stepComponents.keys().reduce((components, fileName) => {
    const componentName = fileName.split('/').pop().replace(/\.\w+$/, '');
    components[componentName] = stepComponents(fileName).default;
    return components;
}, {});
import FluentEmoji from '../components/utils/FluentEmoji.vue'
import { supabase } from '../supabase'
import Menu from '../components/Menu.vue'

export default {
    name: 'Dashboard',

    data() {
        return {
            loading: false 
        }
    },
    props: {
        view: {
            type: String,
            required: true
        },
        linksMenu: {
            type: Array,
            required: true
        },
        linksMenuBottom: {
            type: Array,
            required: true
        }
    },

    components: {
        ...components,
        FluentEmoji,
        Menu
    },

    computed: {
        currentMenuItem() {
            return this.linksMenu.find(link => link.view === this.view) ||
                this.linksMenuBottom.find(link => link.view === this.view);
        }
    }, 
}
</script>

<style lang="scss">
.dashboard {
    display: flex;
    background-color: #F5F5F5;

    .title {
        font-weight: 700;
    }

    .menu {
        width: 300px;
        padding: 20px;

        .bottom {
            border-top: 1px solid #ECE6F0;
            padding-top: 20px;
        }
    }

    .elm {
        button {
            display: flex;
            justify-content: flex-start;
            border-radius: 100px !important;
        }
    }

    .view {
        flex: 1;
        overflow: auto;
        background-color: white;
        padding: 30px 40px;
        border-top-left-radius: 20px;
    }

    .subtitle{
        font-weight: 500!important;
    }
}
</style>