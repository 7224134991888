<template>
    <div>
        <div id="carousel">
            <Carousel :testimonials="testimonials" :spotlight="spotlight" />
        </div>
    </div>
</template>

<script>
import Carousel from '../components/Carousel.vue';

export default {
    props: {
        testimonials: {
            type: Array,
            default: [],
        },
        spotlight: {
            type: Object,
            default: null,
        },
        testing: {
            type: Boolean,
            default: null,
        },
    },
    components: {
        Carousel
    },
    mounted() {
        this.moveCarousel(this.spotlight.carousel_target)
    }, 
    methods: {
        moveCarousel(targetId) {
            const carousel = document.getElementById('carousel');
            const targetDiv = document.getElementById(targetId);
            if (carousel && targetDiv) {
                targetDiv.insertBefore(carousel, targetDiv.firstChild);
            } else if (!targetDiv && !this.testing) {
                document.body.insertBefore(carousel, document.body.firstChild);
            }
        }
    }
};
</script>
