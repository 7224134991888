<template>
    <div v-if="!loading && spotlight">
        <div v-if="spotlight.type == 'floating'">
            <Floating :testimonials="testimonials" :spotlight="spotlight" :error="error" :addView="addView" />
        </div>
        <div v-else>
            <Carousel :testing="spotlightP != undefined" :testimonials="testimonials" :spotlight="spotlight"
                :error="error" :addView="addView" />
        </div>
    </div>
</template>

<script>
import Floating from './Floating.vue';
import Carousel from './Carousel.vue';

export default {
    props: {
        editing: {
            type: Boolean,
            default: false,
        },
        domain: {
            type: String,
            default: null,
        },
        spotlightP: {
            type: Object,
            default: null,
        },
    },
    components: {
        Floating,
        Carousel
    },
    data() {
        return {
            testimonials: [],
            spotlight: {
                type: 'floating',
            },
            error: null,
            viewPlus: false,
            loading: false,
        };
    },
    async mounted() {
        this.loading = true
        await this.fetchTestimonials();
    },
    methods: {

        async fetchSupa(table, where, join) {
            const supabaseUrl = 'https://ibbtamdgytgjscntvzzc.supabase.co';
            const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImliYnRhbWRneXRnanNjbnR2enpjIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjEzMzY1NTEsImV4cCI6MjAzNjkxMjU1MX0.QApkLafdFRU0mgGgbqZrNDcMRn55-WYocsTyVYvxbGs';

            const selectClause = join ? `*,${join}(*)` : '*';
            const url = `${supabaseUrl}/rest/v1/${table}?select=${selectClause}${where ? `&${where}` : ''}`;

            const response = await fetch(url, {
                headers: {
                    apikey: supabaseKey,
                    Authorization: `Bearer ${supabaseKey}`,
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            });

            if (!response.ok) {
                console.error(`Error fetching data from ${table}:`, response.statusText);
                this.error = `Error fetching data from ${table}: ${response.statusText}`;
                return [];
            }

            return response.json();
        },
        async addView() {
            if (!this.spotlight || this.viewPlus) return;
            const views = this.spotlight.views + 1;
            this.viewPlus = true;
            this.patchSupa('spotlights', `id=eq.${this.spotlight.id}`, 'views', views);
        },
        patchSupa(table, where, key, value) {
            const supabaseUrl = 'https://ibbtamdgytgjscntvzzc.supabase.co';
            const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImliYnRhbWRneXRnanNjbnR2enpjIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjEzMzY1NTEsImV4cCI6MjAzNjkxMjU1MX0.QApkLafdFRU0mgGgbqZrNDcMRn55-WYocsTyVYvxbGs';

            const response = fetch(`${supabaseUrl}/rest/v1/${table}?${where}`, {
                method: 'PATCH',
                headers: {
                    apikey: supabaseKey,
                    Authorization: `Bearer ${supabaseKey}`,
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                body: JSON.stringify({
                    [key]: value,
                }),
            });
        },
        async fetchTestimonials() {
            if (this.spotlightP) this.spotlight = this.spotlightP;

            else if (!this.spotlightP) {
                let spotlight = document.getElementsByClassName('skooad-widget')[0].getAttribute('spotlight');
                let currentDomain = this.domain || document.location.href;

                // Construct the Supabase query
                let spotlightResponse = await this.fetchSupa(
                    'spotlights',
                    `id=eq.${spotlight}&active=eq.true`,
                    null
                );
                //filter by domain with spotlightResponse.domains wich is array of domains string
                // spotlightResponse = spotlightResponse.filter((spotlight) => {
                //     return spotlight.domains.includes(currentDomain);
                // })

                if (spotlightResponse && spotlightResponse.length) {
                    this.spotlight = spotlightResponse[0];
                } else {
                    console.error('No spotlights found for this domain.');
                    return;
                }
            }
            //tests
            if (!this.spotlight) {
                console.error('No spotlights found for this domain.');
                return;
            }


            let testimonials = await this.fetchSupa('testisofspot', `spotlight=eq.${this.spotlight.id}`, 'testimonials')
            let allTestimonials = await this.fetchSupa('testimonials', null, 'contacts')

            this.testimonials = testimonials.map((t) => {
                let testimonial = allTestimonials.find((at) => at.id === t.testimonial);
                return testimonial;
            });
            this.loading = false
        }
    },
};
</script>
<style lang="scss">
@import '../assets/scss/global.scss';
@import '../assets/scss/_variables.scss';
@import url('https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css');

$taille-bulle : 50px;

.error {
    background-color: rgba(0, 0, 0, 0.8);
    padding: 5px 10px;
    border-radius: 10px;

    p {
        color: white;
    }

}

// .my-widget {
//     position: fixed;
//     right: 10px;
//     top: 50%;
//     transform: translateY(-50%);
//     z-index: 9999;

//     .bubbles {
//         z-index: 9999;
//         background-color: rgba(0, 0, 0, 0.8);
//         padding: 5px 10px;
//         border-radius: 10px;

//         .img {
//             width: $taille-bulle;
//             height: $taille-bulle;
//             border-radius: 100%;
//             margin: 10px 0;

//         }
//     }

//     .testimonial-video {
//         position: absolute;
//         right: 90px;
//         border-radius: 10px;
//         overflow: hidden;
//         top: calc(50% - 150px);
//         height: fit-content;
//         box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

//         video {
//             height: 100%;
//         }
//     }

//     .video-container {
//         width: $taille-bulle;
//         height: $taille-bulle;
//         overflow: hidden;
//         border-radius: 100%;
//         margin: 10px 0;

//         video {
//             width: auto;
//             height: $taille-bulle;
//         }

//     }

//     .bubble.selected {
//         transform: scale(1.1);
//         transition: transform 0.5s;
//     }
// }</style>
