import { createApp, reactive } from "vue"
import App from "./App.vue"
import router from "./router"
import vuetify from "./plugins/vuetify"
import { loadFonts } from "./plugins/webfontloader"
import axios from "axios"
import vue3GoogleLogin from 'vue3-google-login'
import { supabase } from "./supabase"
import VueGtag from "vue-gtag";
import VueVideoPlayer from '@videojs-player/vue'
import 'video.js/dist/video-js.css'
import { loadStripe } from '@stripe/stripe-js'

loadFonts()

const app = createApp(App)

app.use(VueVideoPlayer)

app.use(vue3GoogleLogin, {
    clientId: '265222787436-4naj0i2ips6thqsvfdf6vnd12f9ivk18.apps.googleusercontent.com'
})

// Define your root data
app.config.globalProperties.$r = reactive({
    config: {
        domain:
            process.env.NODE_ENV === "production" ? "https://app.skooad.com/" : "http://localhost:8080/",
        service_video: process.env.NODE_ENV === "production" ? "https://app.skooad.com/api/" : "http://localhost:3000/",
        videos: process.env.NODE_ENV === "production" ? "https://skooad.com/skooad/testimonial/" : "http://localhost:8080/uploads/",
        images: process.env.NODE_ENV === "production" ? "https://app.skooad.com/skooad/testimonial/images/" : "http://localhost:8080/uploads/images/",
        pricesURL: process.env.NODE_ENV === "production" ? "https://skooad.com/#pricing" : "http://localhost:8080/user/register?gopro=true"
    },
    message: {
        type: null,
        text: null,
        detail: null,
        delay: 15,
    },
    createStory: {
        is: false
    },
    user: null,
    loading: false,
    mobile: false,

    maxStoriesFree: 15,

    magnets: [],
    spotlights: [],
    testimonials: [],
    contacts: [],
    testisofspot: [],

    dialogConfirm: {
        is: false,
        title: '',
        message: '',
        callback: () => { }
    },

    isMobile() {
        return window.innerWidth < 1000
    },

    async loadAll() {
        this.loading = true
        if (this.user) {
            await this.loadUser()
            //go to /register-form if not already 
            if (!this.user.informations.registered&&document.location.pathname!='/register-form') document.location.href = '/register-form'
            await this.loadMagnet()
            await this.loadStories()
            await this.loadSpotlights()
            await this.loadContacts()
            await this.loadTestisOfSpot()
            await this.verifyPro()
        }
        this.loading = false
        return
    },
    reloadPage() {
        document.location.reload()
    },
    async loadTestisOfSpot() {
        let testisofspot = await supabase.from('testisofspot').select('*').eq('user', this.user.id)
        this.testisofspot = testisofspot.data
    },
    async loadSpotlights() {
        let spotlights = await supabase.from('spotlights').select('*').eq('user', this.user.id)
        this.spotlights = spotlights.data
    },
    async loadMagnet() {
        let magnets = await supabase.from('magnets').select('*, testimonials(*)').eq('user', this.user.id)
        magnets.data?.forEach(magnet => {
            magnet.testimonials = magnet.testimonials.filter(testimonial => testimonial.contact)
        })
        this.magnets = magnets.data
    },
    async loadStories() {
        let stories = await supabase.from('testimonials')
            .select(` *, magnets(*), contacts(*) `)
            .order('created_at', { ascending: false })
        stories.data = stories.data.filter((t) => {
            return t.magnets?.user == this.user?.id || t.user == this.user?.id
        })

        stories.data = stories.data.filter(story => story.contacts?.email)
        this.stories = stories.data
    },
    async loadContacts() {
        let contacts = await supabase.from('contacts').select('*, testimonials(*)').eq('user', this.user.id)
        this.contacts = contacts.data
    },

    async loadUser() {
        let user = await supabase.auth?.getSession();
        this.user = user?.data?.session?.user;
        let userInfos = await supabase.from('users').select('*').eq('id', this.user?.id)
        this.user.informations = userInfos.data[0]
    },
    async verifyPro() {
        if (this.user?.informations?.stripe_id) {
            const { data: { session }, error } = await supabase.auth.getSession();
            let body = {
                customerId: this.user?.informations.stripe_id,
                acessToken: session.access_token,
                userId: this.user.id
            }
            const response = await fetch(`${this.config.service_video}payements/verify-pro`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            });

            const data = await response.json();
            if (data.subscription == false) {
                this.user.informations.pro = false
            }
        }
    },

    async sendMail(to, subject, template, message, params) {
        let acessToken
        if (template) {
            const { data: { session }, error } = await supabase.auth.getSession();
            acessToken = session.access_token
        }
        let body = {
            to: to,
            subject: subject,
            text: message,
            template: template,
            acessToken: acessToken,
            params: params
        }
        const response = await fetch(`${this.config.service_video}mail/send-mail`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });
    },

    timefrom(date) {
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        return new Date(date).toLocaleDateString('en-GB', options);
    },

    async deleteTestimonial(id) {
        const response = await supabase.from('testimonials').delete().eq('id', id)
        return response
    },

    async newWidget(id, router) {
        if (!id) {
            const response = await supabase.from('magnets').insert([
                { name: 'My new magnet', user: this.user.id }
            ]).select()

            //add a question to the new magnet
            const response2 = await supabase.from('questions').insert([
                { magnet: response?.data[0].id, question: 'What do you think about my service?' }
            ]).select()

            router.push({ name: 'magnet', params: { id: response?.data[0].id } })
        } else {
            router.push({ name: 'magnet', params: { id } })
        }
    },

    async newSpotlight(id, router) {
        if (!id) {
            const response = await supabase.from('spotlights').insert([
                { name: 'My new spotlight', user: this.user.id }
            ]).select()
            router.push({ name: 'spotlight', params: { id: response?.data[0].id } })
        } else {
            router.push({ name: 'spotlight', params: { id } })
        }
    },

    setMessage(type, text) {
        this.message.type = type
        this.message.text = text
        this.message.delay = 2
    },

    //function return black if the color is light, white if the color is dark
    getTextColor(bgcolor) {
        function hexToRgb(hex) {
            hex = hex.replace(/^#/, '')
            let bigint = parseInt(hex, 16)
            let r = (bigint >> 16) & 255
            let g = (bigint >> 8) & 255
            let b = bigint & 255

            return [r, g, b]
        }

        function luminance(r, g, b) {
            let a = [r, g, b].map(function (v) {
                v /= 255
                return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4)
            })
            return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722
        }

        const rgb = hexToRgb(bgcolor || '#FFFF')
        const lum = luminance(rgb[0], rgb[1], rgb[2])

        return lum < 0.6 ? 'white' : '#403e47'
    },

    formatDate(date, format) {
        if (date) {
            const d = new Date(date)
            let day = d.getDate()
            let month = d.getMonth() + 1
            let year = d.getFullYear()
            if (day < 10) {
                day = '0' + day
            }
            if (month < 10) {
                month = '0' + month
            }
            return format.replace('dd', day).replace('mm', month).replace('yyyy', year)
        } else return null
    },

    async uuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0, // Generate a random number between 0 and 15
                v = c === 'x' ? r : (r & 0x3 | 0x8); // Use specific values for 'y' (8, 9, A, or B)
            return v.toString(16); // Convert to hexadecimal
        });
    },

    copyToClipboard(text) {
        navigator.clipboard.writeText(text).then(() => {
            this.setMessage('success', 'Copied to clipboard')
        })
    },

    async updateValueInTable(table, wherekey, wherevalue, column, value) {

        let body = {};
        body[column] = value
        const response = await supabase
            .from(table)
            .update(body)
            .eq(wherekey, wherevalue)
            .select('*')

        return response
    },

    async InsertValueInTable(table, values) {

        try {
            const response = await supabase
                .from(table)
                .insert(values);

            if (response.error) {
                console.error('Error inserting values:', response.error);
                return null;
            }

            console.log('Insert successful:', response);
            return response;
        } catch (error) {
            console.error('Unexpected error:', error);
            return null;
        }
    },

    testAbonnement() {

    },

    async loadStripe() {
        return await loadStripe(
            process.env.VUE_APP_STRIPE_API
        )
    },

    async getValuesFromTable(table, select, wherekey, wherevalue, getAll = false) {
        let response
        if (!wherekey) {
            response = await supabase
                .from(table)
                .select(select)
        } else {
            response = await supabase
                .from(table)
                .select(select)
                .eq(wherekey, wherevalue)
        }
        if (getAll) return response?.data
        else return response?.data?.[0]?.[select]
    },

    openInNewTab(url) {
        const win = window.open(url, '_blank')
        win.focus()
    },

    async uploadFile(file, lastFile, directory) {
        const formData = new FormData();
        if (!file) {
            const response = await fetch(`${this.config.service_video}file/delete/file/${lastFile}`, {
                method: 'POST',
                body: formData
            });

            return;

        }
        formData.append('image', file);
        formData.append('uuid', await this.uuid());
        if (directory) formData.append('directory', directory);
        if (lastFile) formData.append('lastFile', lastFile);

        try {
            const response = await fetch(this.config.service_video + 'file/upload/file', {
                method: 'POST',
                body: formData
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            console.log('File uploaded successfully:', result);
            return result.fileName;
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    },

    async logout() {
        const { error } = await supabase.auth.signOut();
        this.user = null
        document.location.href = '/'
    },

    goto(route) {
        router.push(route)
    }
})

app.use(router).use(vuetify).mount("#app")
